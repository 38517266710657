import React from "react";
import { Link } from "react-router-dom";
import "../customCss/navbar.css";

function Navbar() {
  return (
    <nav className="navbar">
      <ul className="navbar-menu">
      <li><Link to="/">Anasayfa</Link></li>
        <li><Link to="/about">Hakkımda</Link></li>
      
        {/* <li><Link to="/blog">Blog</Link></li> 
        <li><Link to="/adminPanel">AdminPanel</Link></li>  */}
      
      </ul>
    </nav>
  );
}

export default Navbar;
