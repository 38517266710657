import React, { useState } from "react";

import "../customCss/home.css";
import BuyMeACoffeeButton from './BuyMeCoffee';
import MiniCalculator from './MiniCalculator';
 
<link
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"
  rel="stylesheet"
/>

function About() {
  
  return (
    <section className="home">
      <div className="home-content">
        {/* <div className="profile-photo">
          <img src="/path/to/your-photo.jpg" alt="Serhat Halil Silsüpür" />
        </div> */}
        <h1>Serhat Halil Silsüpür</h1>
        <h3>Yazılım Geliştirici</h3>
        <p>
          Yenilikçi çözümler üreten, problem çözmeyi seven bir yazılım
          geliştiriciyim. 2014 yılından beri Türkiye'nin önde gelen bilişim firmaları ve kamu kurumlarında yazılım geliştirme,
          uygulama geliştirme danışmanlığı, eğitmenlik gibi görevlerde bulundum.
          Yeni teknolojileri öğrenmek ve bunları projelerde kullanmak benim için bir tutku.
        </p>

        <div className="social-media-links">
          <a href="https://www.linkedin.com/in/serhat-halil-sils%C3%BCp%C3%BCr-294a10a9/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin"></i>
          </a>

          <a href="https://www.youtube.com/@SHS_motors" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-youtube"></i>
          </a>
        </div>
     

     
      </div>
      <div>
      <div>   
    
         
        </div>

      </div>
      <BuyMeACoffeeButton />
    </section>

  );
}

export default About;
