import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import axios from "axios"; // Axios'u ekleyin
import "../customCss/blog.css";
import { categories } from "../consts";
import Badge from "@mui/material/Badge";
Modal.setAppElement("#root"); // Accessibility için root elementi ayarlayın

function Blog() {
    const [blogPosts, setBlogPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    // Blog verilerini yükle
    // const fetchBlogs = async (page = 1) => {
    //     try {
    //         const response = await axios.get("http://localhost:5001/blogs", {
    //             params: { page, limit: 5 }, // Sayfa ve limit belirliyoruz
    //         });
    //         console.log(response.data)
    //         setBlogPosts(response.data); // Gelen veriyi state'e ata
    //         setTotalPages(response.data.totalPages);
    //         setCurrentPage(response.data.currentPage);
    //     } catch (error) {
    //         console.error("Blogları yüklerken hata oluştu:", error);
    //     }
    // };

    const fetchBlogs = async (page = 1) => {
        try {
            const response = await axios.get("https://localhost:5001/blogs", {
                params: { page, limit: 5 },
            });
            console.log(response.data); // Yanıtı kontrol edin
            setBlogPosts(response.data); // Dizi doğru katmandaysa alın
            setTotalPages(response.data.totalPages);
            setCurrentPage(response.data.currentPage);
        } catch (error) {
            console.error("Blogları yüklerken hata oluştu:", error);
        }
    };
    

    useEffect(() => {
        fetchBlogs(currentPage);
    }, [currentPage]);

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedPost, setSelectedPost] = useState(null);

    const openModal = (post) => {
        setSelectedPost(post);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSelectedPost(null);
    };

    const Pagination = ({ totalPages, currentPage, onPageChange }) => {
        const pages = Array.from({ length: totalPages }, (_, index) => index + 1);

        return (
            <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                {pages.map((page) => (
                    <button
                        key={page}
                        style={{
                            margin: "0 5px",
                            padding: "5px 10px",
                            backgroundColor: page === currentPage ? "blue" : "lightgray",
                            color: "white",
                            border: "none",
                            cursor: "pointer",
                        }}
                        onClick={() => onPageChange(page)}
                    >
                        {page}
                    </button>
                ))}
            </div>
        );
    };


    return (
        <section className="blog" style={{ "marginTop": "100px" }}>
            <h2>Blog Yazıları</h2>

            {/* <div className="blog-container">
                {blogPosts.map((post) => (
                    
                    <div key={post.id} className="blog-card" onClick={() => openModal(post)}>
                        {post.image && ( // Eğer resim varsa göster
                            <img src={`http://localhost:5001/${post.image}`} alt={post.title} className="blog-image" />
                        )}
                        <div className="blog-content">
                            <h3>{post.title}</h3>
                            <p className="blog-date">{post.date}</p>
                            <p>{post.description}</p>
                            <Badge
                            badgeContent={post.category} // API'den gelen kategori bilgisi
                            color="primary"
                            sx={{
                                "& .MuiBadge-badge": {
                                    right: "-300px",
                                    top: "8px",
                                    fontSize: "0.8rem",
                                    padding: "0 4px",
                                    backgroundColor: "#4caf50", // Rozet arka plan rengi
                                    color: "#fff", // Metin rengi
                                    borderRadius: "4px",
                                },
                            }}
                        />
                        </div>
                    </div>
                ))}
             
            </div> */}
             <div className="blog-container">
                {blogPosts.map((post) => {
                    // Find the category object based on the post's category name
                    const category = categories.find(cat => cat.name === post.category);
                    const badgeColor = category ? category.badgeColor : "default"; // Default color if not found

                    return (
                        <div key={post.id} className="blog-card" onClick={() => openModal(post)}>
                            {post.image && (
                                <img src={`http://localhost:5001/${post.image}`} alt={post.title} className="blog-image" />
                            )}
                            <div className="blog-content">
                                <h3>{post.title}</h3>
                                <p className="blog-date">{post.date}</p>
                                <p>{post.description}</p>
                                <Badge
                                    badgeContent={post.category} // API'den gelen kategori bilgisi
                                    color={badgeColor} // Set color from category
                                    sx={{
                                        "& .MuiBadge-badge": {
                                            right: "-300px",
                                            top: "8px",
                                            fontSize: "0.8rem",
                                            padding: "0 4px",
                                            color: "#fff",
                                            borderRadius: "4px",
                                        },
                                    }}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
            <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                />
            {selectedPost && (
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Blog Detayları"
                    className="modal"
                    overlayClassName="overlay"
                >
                    <h2>{selectedPost.title}</h2>
                    <p className="blog-date">{new Date(selectedPost.createdAt).toLocaleDateString()}</p>

                    <p>{selectedPost.content}</p>
                    <button onClick={closeModal} className="close-btn">Kapat</button>
                </Modal>
            )}
        </section>
    );
}

export default Blog;
