import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from 'sweetalert2';
import "../customCss/adminPanel.css";
import Button from "@mui/material/Button";
import { TextField, Typography, Box, InputLabel, Select, FormControl, MenuItem } from "@mui/material";
import { categories } from "../consts";
function AdminPanel() {
    const [blogs, setBlogs] = useState([]);

    const [form, setForm] = useState({ title: "", description: "", content: "", image: "", category: "" });
    const [editId, setEditId] = useState(null);
    const [file, setFile] = useState(null);
    const API_URL = "http://localhost:5001/blogs";

    // Blogları Getirme
    useEffect(() => {
        fetchBlogs();
    }, []);

    const fetchBlogs = async () => {
        try {
            const response = await axios.get(API_URL);
            setBlogs(response.data);
        } catch (err) {
            console.error(err);
        }
    };
    const handleFileChange = (e) => {
        setForm((prevForm) => ({
            ...prevForm,
            image: e.target.files[0], // Seçilen dosyayı form state'ine ekle
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // FormData nesnesi oluştur
        const formData = new FormData();
        formData.append("title", form.title);
        formData.append("description", form.description);
        formData.append("content", form.content);
        formData.append("category", form.category);

        // Eğer bir dosya yüklendiyse ekle
        if (form.image) {
            formData.append("image", form.image); // 'image' ile dosya adını API'de beklediğiniz isimle eşleştirin
        }

        try {
            if (editId) {
                // Güncelleme
                await axios.put(`${API_URL}/${editId}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data', // İçerik tipi
                    },
                });
                setEditId(null);
            } else {
                // Ekleme
                await axios.post(API_URL, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data', // İçerik tipi
                    },
                });
            }
            setForm({ title: "", description: "", content: "", image: null, category:"" }); // Formu sıfırla
            fetchBlogs(); // Blogları yeniden yükle
        } catch (err) {
            console.error("Hata:", err);
        }
    };


    const handleDelete = async (id) => {
        console.log("id", id)
        // SweetAlert ile onay diyaloğu
        Swal.fire({
            title: 'Emin misiniz?',
            text: 'Bu işlem geri alınamaz!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Evet, sil!',
            cancelButtonText: 'İptal',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.delete(`http://localhost:5001/blogs/${id}`);
                    Swal.fire('Silindi!', 'Blog başarıyla silindi.', 'success');
                    fetchBlogs(); // Blogları yeniden yükle
                } catch (error) {
                    console.log(error)
                    Swal.fire('Hata!', 'Silme işlemi başarısız oldu.', 'error');
                }
            }
        });
    };

    // Form Değişikliği
    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm((prevForm) => ({ ...prevForm, [name]: value }));
    };

    // Blog Düzenleme
    const handleEdit = (blog) => {
        setForm({ title: blog.title, description: blog.description, content: blog.content, image: blog.image, category:blog.category });
        setEditId(blog._id);
    };


    return (
        <div className="admin-panel">
            <Typography variant="h4" gutterBottom style={{marginTop:"20px"}}>
                Blog Yönetim Paneli
            </Typography>

            <form onSubmit={handleSubmit}>
                <Box mb={2}>
                    <TextField
                        label="Başlık"
                        name="title"
                        value={form.title}
                        onChange={handleChange}
                        fullWidth
                        required
                    />
                </Box>
                <Box mb={2}>
                    <TextField
                        label="Kısa Açıklama"
                        name="description"
                        value={form.description}
                        onChange={handleChange}
                        fullWidth
                        required
                    />
                </Box>
                <Box mb={2}>
                    <TextField
                        label="İçerik"
                        name="content"
                        value={form.content}
                        onChange={handleChange}
                        fullWidth
                        required
                        multiline
                        rows={4}
                    />
                </Box>

                <Box mb={2} display="flex" alignItems="center" gap={2}>
                    <input
                        type="file"
                        onChange={handleFileChange}
                        accept="image/*"
                        required
                        style={{ flex: 1 }} // Büyüklüğünü dengelemek için
                    />

                    <FormControl style={{ flex: 2 }}>
                        <InputLabel id="category-label">Kategori</InputLabel>
                        <Select
                            labelId="category-label"
                            id="category-select"
                            name="category"
                            value={form.category}
                            onChange={handleChange}
                            label="Kategori"
                        >
                            {categories.map((category) => (
                                <MenuItem key={category.id} value={category.name}>
                                    {category.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>


                <Button type="submit" variant="contained" color="primary">
                    {editId ? "Güncelle" : "Ekle"}
                </Button>
            </form>

            <div className="blog-list">
                {blogs.map((blog) => (
                    <Box key={blog._id} mb={2} p={2} border={1} borderRadius={4}>
                        <Typography variant="h6">{blog.title}</Typography>
                        <Typography>{blog.description}</Typography>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => handleEdit(blog)}
                            style={{ marginRight: "8px" }}
                        >
                            Düzenle
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => handleDelete(blog._id)}
                        >
                            Sil
                        </Button>
                    </Box>
                ))}
            </div>
        </div>
    );
}

export default AdminPanel;
