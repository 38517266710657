import React from "react";
import "../customCss/home.css";
import BuyMeACoffeeButton from './BuyMeCoffee';
import MiniCalculator from './MiniCalculator';
import LoanCalculator from './LoanCalculator';
<link
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"
  rel="stylesheet"
/>

function Home() {
  return (
    <section className="home">
      <div className="home-content">

        <div
         style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          gap: "20px",
          padding: "10px 20px",
          marginTop: "-20px",
          minHeight: "300px", // Minimum yükseklik ekledik
        }}
        >
          <MiniCalculator />
          <LoanCalculator />
        </div>

        <div>
          <BuyMeACoffeeButton />
        </div>
      </div>
    </section>
  );
}

export default Home;
